/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import styled from '@emotion/styled';
// material
import { alpha, styled as styledMaterial } from '@material-ui/core/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@material-ui/core';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import { MHidden } from '../../components/@material-extend';
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------
const NAV = [
  {
    title: 'Trang chủ',
    path: '/'
  },
  {
    title: 'Giới thiệu',
    path: '/about-us'
  },
  {
    title: 'Sản phẩm',
    path: '/dashboard/e-commerce/shop/default'
  },
  {
    title: 'Dịch vụ',
    path: '/dashboard/service/posts'
  },
  {
    title: 'Tin tức',
    path: '/dashboard/blog/posts'
  },
  {
    title: 'Liên hệ',
    path: '/contact-us'
  }
];
const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styledMaterial(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`
  }
}));

const ToolbarStyle = styledMaterial(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { isCollapse } = useCollapseDrawer();
  const navigate = useNavigate();

  const [isScrolled, setIsScrolled] = useState(false);
  const [navActive, setNavActive] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (window.innerWidth > 1023) {
        setIsScrolled(scrollPosition > 100);
      } else if (window.innerWidth > 639) {
        setIsScrolled(scrollPosition > 80);
      } else {
        setIsScrolled(scrollPosition > 57);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Wrapper isScrolled={isScrolled} isCollapse={isCollapse}>
      <RootStyle
        sx={{
          ...(isCollapse && {
            width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
          })
        }}
      >
        <ToolbarStyle style={{ padding: 0, minHeight: 'unset' }}>
          <MHidden width="lgUp">
            <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
              <Icon icon={menu2Fill} />
            </IconButton>
          </MHidden>

          {/* <Searchbar /> */}
          {/* <Box sx={{ flexGrow: 1 }} /> */}

          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }} style={{ width: '100%' }}>
            {/* <LanguagePopover /> */}
            {/* <NotificationsPopover /> */}
            {/* <ContactsPopover /> */}
            {/* <AccountPopover /> */}
            <NavMenu>
              <MenuItem className="nav inner">
                <div
                  onClick={() => setNavActive(!navActive)}
                  className={navActive ? 'nav__menu-bar active' : 'nav__menu-bar inactive'}
                >
                  <div />
                  <div />
                  <div />
                </div>

                <Item isScrolled={isScrolled} className={`${navActive ? 'active' : ''} nav__menu-list`}>
                  {_.map(NAV, (nav, idx) => (
                    <Link
                      key={idx}
                      onClick={() => {
                        setNavActive(false);
                        navigate(nav.path);
                      }}
                    >
                      {nav.title}
                    </Link>
                  ))}
                </Item>
              </MenuItem>
            </NavMenu>
          </Stack>
        </ToolbarStyle>
      </RootStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .inner {
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 408px;

    @media screen and (max-width: 1919px) {
      padding: 0 50px;
    }

    @media screen and (max-width: 1535px) {
      padding: 0 50px;
    }

    @media screen and (max-width: 1279px) {
      padding: 0 20px;
    }
  }

  .nav__menu-bar {
    width: 24px;
    display: flex;
    flex-direction: column;
  }
  .nav__menu-bar div {
    border-radius: 2px;
    height: 3px;
    background-color: #000;
  }
  .nav__menu-bar div:nth-child(2) {
    margin: 6px 0;
  }

  .nav__menu-list {
    display: flex;
    flex-direction: column;
    position: fixed;
    // top: 111px;
    width: 288px;
    row-gap: 24px;
    right: -288px;
    padding: 24px 16px;
    transition: all 0.2s;
    min-height: calc(100vh - 60px);
    background-color: #fff;
  }
  .nav__menu-list.active {
    right: 0;
  }

  // @media screen and (min-width: 768px) {
  //   .nav__menu-list {
  //     top: 140px;
  //   }
  // }

  @media screen and (min-width: 1024px) {
    .nav__menu-bar {
      display: none;
    }
    .nav__menu-list {
      position: unset;
      flex-direction: row;
      min-height: fit-content;
      width: fit-content;
      column-gap: 24px;
      align-items: center;
      background-color: transparent;
    }
  }
  .nav__menu-bar.inactive div:first-child {
    animation: revmenuAnim1 0.3s ease-in-out;
  }
  .nav__menu-bar.inactive div:nth-child(2) {
    animation: revmenuAnim2 0.3s ease-in-out;
  }
  .nav__menu-bar.inactive div:nth-child(3) {
    animation: revmenuAnim3 0.3s ease-in-out;
  }

  .nav__menu-bar.active div:first-child {
    animation: menuAnim1 0.3s ease-in-out forwards;
  }
  .nav__menu-bar.active div:nth-child(2) {
    animation: menuAnim2 0.3s ease-in-out forwards;
  }
  .nav__menu-bar.active div:nth-child(3) {
    animation: menuAnim3 0.3s ease-in-out forwards;
  }

  @keyframes revmenuAnim1 {
    0% {
      transform: translateY(12px) rotate(45deg);
    }
    70% {
      transform: translateY(12px);
    }
    to {
      transform: translateY(0) rotate(0deg);
    }
  }

  @keyframes revmenuAnim2 {
    0% {
      transform: scale(0);
      opacity: 1;
    }
    to {
      transform: scale(1);
      opacity: 0;
    }
  }

  @keyframes revmenuAnim3 {
    0% {
      transform: translateY(-12px) rotate(135deg);
    }
    70% {
      transform: translateY(-12px);
    }
    to {
      transform: translateY(0) rotate(0);
    }
  }

  @keyframes menuAnim1 {
    0% {
      transform: translateY(0);
    }
    70% {
      transform: translateY(10px);
    }
    to {
      transform: translateY(10px) rotate(45deg);
    }
  }

  @keyframes menuAnim2 {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    to {
      transform: scale(0);
      opacity: 0;
    }
  }

  @keyframes menuAnim3 {
    0% {
      transform: translateY(0);
    }
    70% {
      transform: translateY(-9px);
    }
    to {
      transform: translateY(-9px) rotate(135deg);
    }
  }
`;

const NavMenu = styled.div`
  width: 100%;
  box-shadow: 0 0 #0000, 0 0 #000, 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  background: #fff;
  /* background: linear-gradient(180deg, #7cd3f6 0%, #bbe5fb 100%); */
`;

const MenuItem = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 35px;

  @media screen and (min-width: 1024px) {
    justify-content: center;
    height: 52px;
  }
`;

const Item = styled.div`
  top: ${({ isScrolled }) => (isScrolled ? '35px' : '90px')};

  @media screen and (min-width: 768px) {
    /* top: ${({ isScrolled }) => (isScrolled ? '35px' : '120px')}; */
    top: ${({ isScrolled }) => (isScrolled ? '35px' : '38px')};
  }
`;

const Link = styled.div`
  margin-right: 20px;
  color: #000;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  border-bottom: 3px solid transparent;

  :hover {
    border-color: #000;
  }

  @media screen and (min-width: 1024px) {
    /* color: #fff; */
    color: #000;
  }
`;
