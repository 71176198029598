import PropTypes from 'prop-types';
import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { apiDomain } from '../config';

// ----------------------------------------------------------------------

const initialState = {
  products: [],
  categories: [],
  blogs: [],
  services: []
};

const DataContext = createContext(initialState);

DataProvider.propTypes = {
  children: PropTypes.node
};

function DataProvider({ children }) {
  const [dataProducts, $dataProducts] = useState([]);
  const [dataCategories, $dataCategories] = useState([]);
  const [dataBlogs, $dataBlogs] = useState([]);
  const [dataServices, $dataServices] = useState([]);

  useEffect(() => {
    axios
      .get(`${apiDomain}/products?populate=*&pagination[start]=0&pagination[limit]=10`, {})
      .then((res) => {
        $dataProducts(_.get(res, 'data.data'));
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(`${apiDomain}/categories`, {})
      .then((res) => {
        $dataCategories(_.get(res, 'data.data'));
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(`${apiDomain}/blogs?populate=*`, {})
      .then((res) => {
        $dataBlogs(_.get(res, 'data.data'));
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(`${apiDomain}/services?populate=*`, {})
      .then((res) => {
        $dataServices(_.get(res, 'data.data'));
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <DataContext.Provider
      value={{
        products: dataProducts,
        categories: dataCategories,
        blogs: dataBlogs,
        services: dataServices
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

export { DataProvider, DataContext };
