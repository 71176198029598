import _ from 'lodash';
import { useRef, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import ArrowUp from '@iconify/icons-ant-design/arrow-up';
import { Icon } from '@iconify/react';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@material-ui/core';
import { alpha, styled as styledMaterial } from '@material-ui/core/styles';

import Facebook from '@iconify/icons-ant-design/facebook-filled';
import Skype from '@iconify/icons-ant-design/skype-filled';
import Phone from '@iconify/icons-ant-design/phone-filled';
import Zalo from '../assets/LogoZalo.webp';
import LogoDeli from '../assets/logo.png';
import useCollapseDrawer from '../hooks/useCollapseDrawer';

export const NAVFooter = [
  {
    title1: 'Thông tin liên hệ',
    title2: '',
    items: [
      {
        icon: '',
        item: 'CÔNG TY TNHH SẢN XUẤT THƯƠNG MẠI DELIPACK'
      },
      {
        icon: '',
        item: '2A216A đường Vĩnh Lộc, Ấp 1, xã Phạm Văn Hai, huyện Bình Chánh, TPHCM'
      },
      {
        icon: '',
        item: 'SDT: 0901475439'
      },
      {
        icon: '',
        item: 'Email: ctybaobidelipack@gmail.com'
      },
      {
        icon: '',
        item: 'Website: ctybaobidelipack.com'
      }
    ]
  }
];
export const SOCIALS = [
  {
    icon: '',
    href: '/'
  },
  {
    icon: '',
    href: '/'
  },
  {
    icon: '',
    href: '/'
  },
  {
    icon: '',
    href: '/'
  }
];

const View = () => {
  const ref = useRef(null);
  const { isCollapse } = useCollapseDrawer();

  const [isScrolled, setIsScrolled] = useState(false);

  const _onBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (window.innerWidth > 1023) {
        setIsScrolled(scrollPosition > 110);
      } else if (window.innerWidth > 639) {
        setIsScrolled(scrollPosition > 80);
      } else {
        setIsScrolled(scrollPosition > 57);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Footer ref={ref} isCollapse={isCollapse}>
      {isScrolled && (
        <BackToTop onClick={_onBackToTop}>
          <Icon icon={ArrowUp} width={20} height={20} />
        </BackToTop>
      )}

      <BackToTop style={{ bottom: '66px' }}>
        <img src={Zalo} alt="zalo" style={{ width: '20px', height: '20px' }} />
      </BackToTop>
      <BackToTop style={{ bottom: '112px' }}>
        <Icon icon={Facebook} width={20} height={20} color="#3458eb" />
      </BackToTop>
      <BackToTop style={{ bottom: '159px' }}>
        <Icon icon={Skype} width={20} height={20} color="#0078ca" />
      </BackToTop>
      <BackToTop style={{ bottom: '205px' }}>
        <Icon icon={Phone} width={20} height={20} color="#43de6c" />
      </BackToTop>

      <Wrapper className="inner">
        <div style={{ display: 'flex' }}>
          {_.map(NAVFooter, (nav, index) => (
            <div key={index}>
              <Title1 style={{ width: 'fit-content' }}>
                {nav.title1}
                <span style={{ fontWeight: '500' }}> {nav.title2}</span>
                <LineTitle />
              </Title1>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  {_.map(nav.items, (item, idx) => (
                    <div key={idx} style={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                      {_.get(item, 'icon') && (
                        <img src={_.get(item, 'icon', '')} alt="icon" style={{ marginRight: '8px' }} />
                      )}
                      {idx !== 2 && item.item}

                      {idx === 2 && (
                        <>
                          <a href={`tel:${item.item}`}>{item.item}</a>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>

        <Logo>
          <img src={LogoDeli} alt="" fill sizes="(max-width: 768px) 162px, (max-width: 1024px) 200px, 253px" />
        </Logo>
      </Wrapper>

      <Copyright>Copyright</Copyright>
    </Footer>
  );
};
export default View;
const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const Footer = styled.div`
  padding-top: 24px;
  position: relative;
  margin-left: auto;
  background: linear-gradient(180deg, #7cd3f6 0%, #bbe5fb 100%);
  width: 100%;
  @media screen and (min-width: 1200px) {
    width: ${({ isCollapse }) => (isCollapse ? `calc(100% - ${COLLAPSE_WIDTH}px)` : `calc(100% - ${DRAWER_WIDTH}px)`)};
  }
  .inner {
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 408px;

    @media screen and (max-width: 1919px) {
      max-width: 1204px;
      padding: 0 50px;
    }

    @media screen and (max-width: 1535px) {
      padding: 0 50px;
    }

    @media screen and (max-width: 1279px) {
      padding: 0 20px;
    }
  }
`;

const BackToTop = styled.div`
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 9999px;
  position: fixed;
  bottom: 20px;
  right: 39px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const Copyright = styled.div`
  height: 74px;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  margin-top: 28px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(180deg, #7cd3f6 0%, #bbe5fb 100%);

  @media screen and (min-width: 640px) {
    height: 66px;
    font-size: 14px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const Title1 = styled.div`
  font-size: 16px;
  color: #005991;
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 16px;
  font-weight: 500;

  @media screen and (min-width: 768px) {
    font-size: 18px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 20px;
  }
`;

const LineTitle = styled.div`
  width: 100%;
  height: 1px;
  position: absolute;
  background: #1a1aff;
  bottom: 0;
  left: 0;
`;

const Logo = styled.div`
  position: relative;
  width: 162px;
  height: 58px;
  margin-bottom: 24px;
  margin-left: auto;

  @media screen and (min-width: 768px) {
    width: 200px;
    height: 73px;
    margin-left: 0;
    margin-bottom: 0;
  }
  @media screen and (min-width: 1024px) {
    width: 253px;
    height: 93px;
  }
`;
